import {
	FETCHING_LINKS,
	GET_LINKS,
	ERROR_LINKS,
	GET_ID_NOTIFICATIONS,
	ERROR_CREATE_PAYMENT_LINK
} from "store/types";

const initialState = {
	links: {},
	fetching: undefined,
	error: false,
	errorMessage: "",
	errorCreatePaymentLink: false
};

const linksReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_LINKS:
			return {
				...state,
				fetching: action?.payload,
			};
		case GET_LINKS:
			return {
				...state,
				links: action?.payload,
				error: false,
				errorMessage: "",
			};
		case ERROR_LINKS:
			return {
				...state,
				error: action?.payload,
				errorMessage: "Ups! Hubo un error al obtener los links de pago",
				fetching: false,
				links: {}
			};
		case GET_ID_NOTIFICATIONS:
			return {
				...state,
				idNotification: action?.payload
			};
		case ERROR_CREATE_PAYMENT_LINK:
			return {
				...state,
				errorCreatePaymentLink: action?.payload
			};
		default:
			return state;
	}
};

export default linksReducer;
import { combineReducers } from "redux";

import authReducer from "./authReducer";
import userReducer from "./userReducer";
import passwordRecoveryReducer from "./passwordRecoveryReducer";
import passwordResetReducer from "./passwordResetReducer";
import sellsReducer from "./sellsReducer";
import saleDetailReducer from "./saleDetail";
import zonesReducer from "./zonesReducer";
import linksReducer from "./linksReducer";
import clientReducer from "./clientReducer";
import routesReducer from "./routesReducer";
import membersReducer from "./membersReducer";
import reportsReducer from "./reportsReducer";
import sidebarReducer from "./sidebarReducer";
import productsReducer from "./productsReducer";
import profilesReducer from "./profilesReducer";
import branchesReducer from "./branchesReducer";
import checkoutReducer from "./checkoutReducer";
import ejecutivesReducer from "./ejecutivesReducer";
import devolutionsReducer from "./devolutionsReducer";
import conciliationsReducer from "./conciliationsReducer";
import integrationsReducer from "./integrationsReducer";
import invoicesReducer from "./invoicesReducer";
import permanentPaymentLinksDetailReducer from "./permanentPaymentLinksDetailReducer";
import permissionsReducer from "./permissionsReducer";
import chargebacksReducer from "./chargebacksReducer";
import conciliationDetailReducer from "./conciliationDetailReducer";
import { CLEAR_STORE } from "store/types";

const appReducer = combineReducers({
	authReducer,
	userReducer,
	passwordRecoveryReducer,
	passwordResetReducer,
	sellsReducer,
	saleDetailReducer,
	zonesReducer,
	linksReducer,
	clientReducer,
	routesReducer,
	membersReducer,
	reportsReducer,
	sidebarReducer,
	productsReducer,
	profilesReducer,
	branchesReducer,
	checkoutReducer,
	ejecutivesReducer,
	devolutionsReducer,
	integrationsReducer,
	conciliationsReducer,
	invoicesReducer,
	permanentPaymentLinksDetailReducer,
	permissionsReducer,
	chargebacksReducer,
	conciliationDetailReducer
});

const rootReducer = (state, action) => {
	if (action.type === CLEAR_STORE) {
		localStorage.clear();
		return appReducer(undefined, action)
	};
	return appReducer(state, action);
};


export default rootReducer;
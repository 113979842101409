import "./styles.scss";

const Marquee = ({ msg }) => (
    <div className="marquee-container">
        <button>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
            <span>{msg}</span>
        </button>
    </div>
);

export default Marquee;
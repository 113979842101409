import {
    GET_PROFILES,
} from "store/types";

const initialState = {};

const profilesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PROFILES:
			return {
                ...state,
				profiles: action?.payload
			};
		default:
			return state;
	}
};

export default profilesReducer;
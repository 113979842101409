import {
    Popover,
    OverlayTrigger,
} from 'react-bootstrap';
import { connect }    from 'react-redux';
import { useHistory } from 'react-router-dom';

import bindAll        from 'helpers/bindAll';
import { PowerOff }   from 'resources/icons';
import StoreActions   from 'store/actions/StoreActions';
import getLettersName from 'helpers/getLettersName';
import "./styles.scss";

const CustomAvatar = ({
    user,
    mobile,
    storeActions,
    placementAvatar,
}) => {

    const history = useHistory();

    const popoverFocus = (
        <Popover title="Popover bottom">
            <div className="avatar__menu">
                <div className="avatar__menu-body">
                    <p className="avatar__menu-body-name">{ user?.firstName }</p>
                    <p className="avatar__menu-body-email">{ user?.email }</p>
                    <ul>
                        <li onClick={() => { history.push("/profile") }}>Perfil</li>
                        {/*<li onClick={() => { history.push("/members") }}>Usuarios</li>*/}
                        {/*<li onClick={() => { history.push("/payment-links/config") }}>Configuración general</li>*/}
                    </ul>
                </div>
                <p
                    onClick={ storeActions.clearStore } 
                    className="avatar__menu-logout"
                >
                    <PowerOff /> Cerrar Sesión
                </p>
            </div>
        </Popover>
    )

    return (
        <OverlayTrigger trigger="focus" placement={placementAvatar} overlay={popoverFocus}>
            {mobile ?
                <button className='avatarMobile'>
                    <span className="avatarMobile__label">
                        { getLettersName(user?.firstName, user?.lastName) }
                    </span>
                </button> :
                <button className="avatarDesktop">
                    <span className="avatarDesktop__label">
                        { getLettersName(user?.firstName, user?.lastName) }
                    </span>
                </button>
            }
        </OverlayTrigger>
    )
};

const mapStateToProps = ({ userReducer }) => ({
    user: userReducer?.business?.administrator ?? {},
});

const mapDispatchToProps = bindAll({ StoreActions });

export default connect(mapStateToProps, mapDispatchToProps)(CustomAvatar)
import {
    FETCHING_CONCILIATION_DETAIL,
    GET_CONCILIATION_DETAIL,
    ERROR_CONCILIATION_DETAIL
} from "store/types";

const initialState = {
    conciliationDetail: [],
    fetching: undefined,
    error: false,
    errorMessage: ""
};

const conciliationDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_CONCILIATION_DETAIL:
            return {
                ...state,
                fetching: action?.payload,
            };
        case GET_CONCILIATION_DETAIL:
            return {
                ...state,
                conciliationDetail: action?.payload,
                error: false,
                errorMessage: ""
            };
        case ERROR_CONCILIATION_DETAIL:
            return {
                ...state,
                error: action?.payload,
                errorMessage: "Ups! Hubo un error al obtener el detalle de la conciliación",
                fetching: false,
                conciliationDetail: []
            };
        default:
            return state;
    }
};

export default conciliationDetailReducer;

import {
    SET_TOKEN,
    REMOVE_TOKEN,
} from "store/types";

const setToken = data => ({
    type: SET_TOKEN,
    payload: data,
});

const removeToken = () => ({
    type: REMOVE_TOKEN,
});

const AuthActions = {
    setToken,
    removeToken,
};

export default AuthActions;
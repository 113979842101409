import {
	FETCHING_SELLS,
	GET_SELLS,
	ERROR_GET_SELLS,
	SET_PAGE_SELL,
	GENERATE_REPORT_SALES,
	FETCHING_GENERATE_REPORT_SALES,
	GENERATING_REPORT_SALES,
	GENERATED_REPORT_SALES,
	ERROR_REPORT_SALES
} from "store/types";

const initialState = {
	sellsPage: 1,
	sells: {},
	fetching: undefined,
	error: false,
	errorMessage: "",
	generatingReport: false,
	generatedReportSales: {},
	generateReportSales: {},
	fetchingGenerateReportSales: false,
	errorReportSales: false,
};

const sellsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_SELLS:
			return {
				...state,
				fetching: action?.payload,
			};
		case GET_SELLS:
			return {
				...state,
				sells: action?.payload,
				error: false,
				errorMessage: ""
			};
		case ERROR_GET_SELLS:
			return {
				...state,
				error: action?.payload,
				errorMessage: "Ups! Hubo un error al obtener las ventas",
				fetching: false,
				sells: {}
			};
		case SET_PAGE_SELL:
			return {
				...state,
				sellsPage: action?.payload
			};
		case GENERATE_REPORT_SALES:
			return {
				...state,
				generateReportSales: action?.payload
			};
		case FETCHING_GENERATE_REPORT_SALES:
			return {
				...state,
				fetchingGenerateReportSales: action?.payload
			}
		case GENERATING_REPORT_SALES:
			return {
				...state,
				generatingReport: action?.payload
			}
		case GENERATED_REPORT_SALES:
			return {
				...state,
				generatedReportSales: action?.payload
			}

		case ERROR_REPORT_SALES:
			return {
				...state,
				errorReportSales: action?.payload
			}
		default:
			return state;
	}
};

export default sellsReducer;

// Auth
export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

// User
export const SET_USER = "SET_USER";

// Password Recovery
export const FETCHING_PASSWORD_RECOVERY = "FETCHING_PASSWORD_RECOVERY";
export const GET_PASSWORD_RECOVERY = "GET_PASSWORD_RECOVERY";
export const ERROR_PASSWORD_RECOVERY = "ERROR_PASSWORD_RECOVERY";

//PasswordReset
export const FETCHING_PASSWORD_RESET = "FETCHING_PASSWORD_RESET";
export const GET_PASSWORD_RESET = "GET_PASSWORD_RESET";
export const ERROR_PASSWORD_RESET = "ERROR_PASSWORD_RESET";

// Clients
export const FETCHING_CLIENTS = "FETCHING_CLIENTS";
export const GET_CLIENTS = "GET_CLIENTS";
export const ERROR_CLIENTS = "ERROR_CLIENTS";
export const SET_PAGE_CLIENT = "SET_PAGE_CLIENT";

// Branches
export const GET_BRANCHES = "GET_BRANCHES";
export const SET_PAGE_BRANCES = "SET_PAGE_BRANCES";
export const FETCHING_ALL_BRANCHES = "FETCHING_ALL_BRANCHES";
export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const ERROR_ALL_BRANCHES = "ERROR_ALL_BRANCHES";

// Zones
export const FETCHING_ZONES = "FETCHING_ZONES";
export const GET_ZONES = "GET_ZONES";
export const ERROR_ZONES = "ERROR_ZONES";
export const SET_PAGE_ZONES = "SET_PAGE_ZONES";
export const GET_ZONES_SELECT = "GET_ZONES_SELECT";

// Ejecutives
export const GET_EJECUTIVES = "GET_EJECUTIVES";

// Ejecutives
export const GET_MEMBERS = "GET_MEMBERS";

// Products
export const FETCHING_PRODUCTS = "FETCHING_PRODUCTS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const ERROR_PRODUCTS = "ERROR_PRODUCTS";
export const SET_PAGE_PRODUCT = "SET_PAGE_PRODUCT";

// Links
export const FETCHING_LINKS = "FETCHING_LINKS";
export const GET_LINKS = "GET_LINKS";
export const ERROR_LINKS = "ERROR_LINKS";
export const GET_ID_NOTIFICATIONS = "GET_ID_NOTIFICATIONS";
export const ERROR_CREATE_PAYMENT_LINK = "ERROR_CREATE_PAYMENT_LINK";

// PermanentPaymentLinks
export const FETCHING_PERMANENT_PAYMENTLINK_DETAIL = 'FETCHING_PERMANENTLINK_PAYMENTLINK_DETAIL';
export const GET_PERMANENT_PAYMENTLINK_DETAIL = 'GET_PERMANENT_PAYMENTLINK_DETAIL';
export const ERROR_PERMANENT_PAYMENTLINK_DETAIL = 'ERROR_PERMANENT_PAYMENTLINK_DETAIL';

// Conciliations
export const FETCHING_CONCILIATIONS = "FETCHING_CONCILIATIONS";
export const GET_CONCILIATIONS = "GET_CONCILIATIONS";
export const ERROR_CONCILIATIONS= "ERROR_CONCILIATIONS";
export const SET_PAGE_CONCILIATION= "SET_PAGE_CONCILIATION";
export const FETCHING_CONCILIATION_DETAIL= "FETCHING_CONCILIATION_DETAIL";
export const GET_CONCILIATION_DETAIL= "GET_CONCILIATION_DETAIL";
export const ERROR_CONCILIATION_DETAIL= "ERROR_CONCILIATION_DETAIL";
export const GENERATE_REPORT_CONCILIATIONS= "GENERATE_REPORT_CONCILIATIONS";
export const FETCHING_GENERATE_REPORT_CONCILIATIONS= "FETCHING_GENERATE_REPORT_CONCILIATIONS";
export const GENERATING_REPORT_CONCILIATIONS= "GENERATING_REPORT_CONCILIATIONS";
export const GENERATED_REPORT_CONCILIATIONS= "GENERATED_REPORT_CONCILIATIONS";
export const ERROR_REPORT_CONCILIATIONS= "ERROR_REPORT_CONCILIATIONS";

// Reports
export const SET_REPORTS = "SET_REPORTS";
export const FETCHING_PAYMENTS_WITH_TERMINALS = "FETCHING_PAYMENTS_WITH_TERMINALS";
export const GET_PAYMENTS_WITH_TERMINALS = "GET_PAYMENTS_WITH_TERMINALS";
export const ERROR_PAYMENTS_WITH_TERMINALS = "ERROR_PAYMENTS_WITH_TERMINALS";

// Sells
export const FETCHING_SELLS = "FETCHING_SELLS";
export const GET_SELLS = "GET_SELLS";
export const ERROR_GET_SELLS = "ERROR_GET_SELLS";
export const SET_PAGE_SELL = "SET_PAGE_SELL";
export const GENERATE_REPORT_SALES = "GENERATE_REPORT_SALES";
export const FETCHING_GENERATE_REPORT_SALES = "FETCHING_GENERATE_REPORT_SALES";
export const GENERATING_REPORT_SALES = "GENERATING_REPORT_SALES";
export const GENERATED_REPORT_SALES = "GENERATED_REPORT_SALES";
export const ERROR_REPORT_SALES = "ERROR_REPORT_SALES";

export const SET_TYPE_SELL = "SET_TYPE_SELL";
export const FETCHING_SALES = "FETCH_SALES";
export const SET_SALES = "SET_SALES";
export const ERROR_SALES = "ERROR_SALES";
export const SET_SALES_PAGE = "SET_SALES_PAGE";

// Sale Detail
export const FETCHING_SALE_DETAIL = "FETCHING_SALE_DETAIL";
export const GET_SALE_DETAIL = "GET_SALE_DETAIL";
export const ERROR_SALE_DETAIL = "ERROR_SALE_DETAIL";

// Devolutions
export const FETCHING_DEVOLUTIONS = "FETCHING_DEVOLUTIONS";
export const ERROR_DEVOLUTIONS = "ERROR_DEVOLUTIONS";
export const SET_DEVOLUTIONS = "SET_DEVOLUTIONS";
export const SET_PAGE_REFUND = "SET_PAGE_REFUND";

// Sells
export const GET_PROFILES = "GET_PROFILES";

// Sidebar
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

// Checkout
export const SET_TOKEN_API = "SET_TOKEN_API";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const GET_PAYMENT_INFO = "GET_PAYMENT_INFO";

// Routes
export const SET_LAST_ROUTE_VISITED = "SET_LAST_ROUTE_VISITED";

// Store
export const CLEAR_STORE = "CLEAR_STORE";

// Store
export const SET_ID_INTEGRATION = "SET_ID_INTEGRATION";

// Invoices
export const FETCHING_INVOICES = "FETCHING_INVOICES";
export const GET_INVOICES = "GET_INVOICES";
export const ERROR_INVOICES = "ERROR_INVOICES";
export const SET_PAGE_INVOICES = "SET_PAGE_INVOICES";

// Permissions
export const PERMISSIONS_PRODUCTS = "PERMISSIONS_PRODUCTS";
export const PERMISSIONS_PAYMENT_LINKS = "PERMISSIONS_PAYMENT_LINKS";
export const PERMISSIONS_BRANCHES = "PERMISSIONS_BRANCHES";
export const PERMISSIONS_ZONES = "PERMISSIONS_ZONES";
export const PERMISSIONS_CLIENTS = "PERMISSIONS_CLIENTS";
export const PERMISSIONS_SALES = "PERMISSIONS_SALES";
export const PERMISSIONS_REFUNDS = "PERMISSIONS_REFUNDS";
export const PERMISSIONS_CHARGEBACKS = "PERMISSIONS_CHARGEBACKS";
export const PERMISSIONS_CONCILIATIONS = "PERMISSIONS_CONCILIATIONS";

// Chargebacks
export const FETCHING_CHARGEBACKS = "FETCHING_CHARGEBACKS";
export const GET_CHARGEBACKS = "GET_CHARGEBACKS";
export const ERROR_CHARGEBACKS = "ERROR_CHARGEBACKS";
export const SET_PAGE_CHARGEBACKS = "SET_PAGE_CHARGEBACKS";
export const FETCHING_CHARGEBACK_DETAIL = "FETCHING_CHARGEBACK_DETAIL";
export const GET_CHARGEBACK_DETAIL = "GET_CHARGEBACK_DETAIL";
export const ERROR_CHARGEBACK_DETAIL = "ERROR_CHARGEBACK_DETAIL";

import {
	FETCHING_CONCILIATIONS,
	GET_CONCILIATIONS,
	ERROR_CONCILIATIONS,
	SET_PAGE_CONCILIATION,
	GENERATE_REPORT_CONCILIATIONS,
	FETCHING_GENERATE_REPORT_CONCILIATIONS,
	GENERATING_REPORT_CONCILIATIONS,
	GENERATED_REPORT_CONCILIATIONS,
	ERROR_REPORT_CONCILIATIONS
} from "store/types";

const initialState = {
	conciliationsPage: 1,
	conciliations: {},
	fetching: undefined,
	error: false,
	errorMessage: "",
	generatingReport: false,
	generatedReportConciliations: {},
	generateReportConciliations: {},
	fetchingGenerateReportConciliations: false,
	errorReportConciliations: false,
};

const conciliationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_CONCILIATIONS:
			return {
				...state,
				fetching: action?.payload,
			};
		case GET_CONCILIATIONS:
			return {
				...state,
				conciliations: action?.payload,
				error: false,
				errorMessage: ""
			};
		case ERROR_CONCILIATIONS:
			return {
				...state,
				error: action?.payload,
				errorMessage: "Ups! Hubo un error al obtener las conciliaciones",
				fetching: false,
				conciliations: {}
			};
		case SET_PAGE_CONCILIATION:
			return {
				...state,
				conciliationsPage: action?.payload
			};
		case GENERATE_REPORT_CONCILIATIONS:
			return {
				...state,
				generateReportConciliations: action?.payload
			};
		case FETCHING_GENERATE_REPORT_CONCILIATIONS:
			return {
				...state,
				fetchingGenerateReportConciliations: action?.payload
			}
		case GENERATING_REPORT_CONCILIATIONS:
			return {
				...state,
				generatingReport: action?.payload
			}
		case GENERATED_REPORT_CONCILIATIONS:
			return {
				...state,
				generatedReportConciliations: action?.payload
			}
		case ERROR_REPORT_CONCILIATIONS:
			return {
				...state,
				errorReportConciliations: action?.payload
			}
		default:
			return state;
	}
};

export default conciliationsReducer;
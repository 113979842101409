import axios from "axios";

const baseURL = process.env.REACT_APP_API;

const axiosClient = axios.create({ baseURL });

export const setAuthorizationHeader = token => {
    axiosClient.defaults.headers.common["Authorization"] = `Bearer ${token?.access_token}`
};

export const responseInterceptorUnauthorized = (authServices, authActions) => {
    // Add a 401 response interceptor
    axiosClient.interceptors.response.use(
        (response) => response,
        (error) => {
            const localStorageToken = localStorage.getItem('accessToken');
            const localStorageUser = localStorage.getItem('infoUser');
            const errorResponseStatus = error.response.status;
            if (
                (localStorageToken === null || localStorageToken === undefined)
                ||
                (localStorageUser === null || localStorageUser === undefined)
            ) {
                authActions.removeToken();
                localStorage.clear();
            }
            if (errorResponseStatus === 401 && localStorageToken && localStorageUser) {
                authServices.refreshToken(info => {
                    if (info) {
                        // Got the refreshToken correctly
                        console.log("refreshToken: ", info);
                    } else {
                        // Got the refreshToken incorrectly
                        console.log("refreshToken: ", info);
                        authActions.removeToken();
                        localStorage.clear();
                    }
                });
            } else {
                console.error("error Unauthorized: ", error);
                return Promise.reject(error);
            }
        }
    );
}

export default axiosClient;
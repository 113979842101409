import React from "react";

const Load = ({ className = "", style = {}, ...rest }) => (
	<svg
		style={{
			height : "1em",
			width  : "1em",
			...style,
		}}
		className={className}
		viewBox="0 0 22.36 16.31"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path fill="currentColor" d="M4.59,6.18a6.51,6.51,0,0,1,2-4.28A6.46,6.46,0,0,1,10.78,0,6.38,6.38,0,0,1,15.5,1.62,6.35,6.35,0,0,1,17.72,6c0,.15,0,.22.21.24a5,5,0,0,1,2.78,1.27,4.82,4.82,0,0,1,1.64,3.59,4.94,4.94,0,0,1-1.72,4,4.68,4.68,0,0,1-3.18,1.23H7.05c-.69,0-1.38,0-2.07,0a5.06,5.06,0,0,1-1.05-10ZM5.4,7H5.19a5.6,5.6,0,0,0-1.57.3A4,4,0,0,0,.85,11.81,4.23,4.23,0,0,0,5.1,15.5q6.11,0,12.19,0a4.26,4.26,0,0,0,4.27-3.95A4.21,4.21,0,0,0,17.64,7L17,7c-.1-.64-.16-1.26-.31-1.86a5.54,5.54,0,0,0-3.13-3.85A5.61,5.61,0,0,0,10.4.85,5.44,5.44,0,0,0,5.84,4.5,6.74,6.74,0,0,0,5.4,7Z" />
		<path fill="currentColor" d="M11.19,4.62l.62.74L14.2,8.22a1.24,1.24,0,0,1,.14.18.29.29,0,0,1-.07.4.31.31,0,0,1-.43,0,.63.63,0,0,1-.11-.12l-2-2.45a1.56,1.56,0,0,1-.15-.17v7.21c0,.26-.13.4-.37.38a.31.31,0,0,1-.31-.31V6.07c-.33.39-.62.74-.92,1.08-.44.52-.87,1-1.31,1.55a.53.53,0,0,1-.3.18A.39.39,0,0,1,8,8.72c0-.08,0-.22,0-.33s0-.06.06-.09l3-3.63Z" />
	</svg>
);

export default Load;
import {
	SET_ID_INTEGRATION,
} from "store/types";

const initialState = {};

const integrationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ID_INTEGRATION:
			return {
                ...state,
				idIntegration: action?.payload,
			};
		default:
			return state;
	}
};

export default integrationsReducer;
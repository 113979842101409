import { TOGGLE_SIDEBAR } from "store/types";

const initialState = {
	sidebar: true,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
			return {
                ...state,
				sidebar: !state.sidebar,
			};
		default:
			return state;
	}
};

export default authReducer;
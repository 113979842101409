import {
    SET_USER,
} from "store/types";

const initialState = {};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER:
			return {
                ...state,
				...action?.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
import {
    SET_LAST_ROUTE_VISITED
} from "store/types";

const initialState = {
	lastRouteVisted: null,
};

const routesReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LAST_ROUTE_VISITED:
			return {
                ...state,
				lastRouteVisted: action?.payload
			};

		default:
			return state;
	}
};

export default routesReducer;
import {
    FETCHING_INVOICES,
    GET_INVOICES,
    ERROR_INVOICES,
    SET_PAGE_INVOICES,
} from "store/types";

const initialState = {
    invoicePage: 1,
    invoices: {},
    fetching: undefined,
    error: false,
    errorMessage: ""
};

const invoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_INVOICES:
            return {
                ...state,
                fetching: action?.payload,
            };
        case GET_INVOICES:
            return {
                ...state,
                invoices: action?.payload,
                error: false,
                errorMessage: ""
            };
        case ERROR_INVOICES:
            return {
                ...state,
                error: action?.payload,
                errorMessage: "Ups! Hubo un error al obtener las facturas",
                fetching: false,
                invoices: {}
            };
        case SET_PAGE_INVOICES:
            return {
                ...state,
                invoicePage: action?.payload
            };
        default:
            return state;
    }
};

export default invoicesReducer;
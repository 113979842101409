import {
	FETCHING_DEVOLUTIONS,
	ERROR_DEVOLUTIONS,
	SET_DEVOLUTIONS,
	SET_PAGE_REFUND
} from "store/types";

const initialState = {
	refundsPage: 1,
	devolutions: {},
	fetching: undefined,
	error: false,
	errorMessage: "",
};

const devolutionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_DEVOLUTIONS:
			return {
				...state,
				fetching: action?.payload,
			};
		case SET_DEVOLUTIONS:
			return {
				...state,
				devolutions: action?.payload,
				error: false,
				errorMessage: "",
			};
		case ERROR_DEVOLUTIONS:
			return {
				...state,
				error: action?.payload,
				errorMessage: "Ups! Hubo un error al obtener las devoluciones",
				fetching: false,
				devolutions: {}
			};
		case SET_PAGE_REFUND:
			return {
				...state,
				refundsPage: action?.payload
			};
		default:
			return state;
	}
};

export default devolutionsReducer;
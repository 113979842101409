import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Redirect, useLocation, useHistory } from "react-router-dom";

import Loader from "components/Ui/Loader";
import AppLayout from "components/layout/AppLayout";
import UnauthorizedPage from "pages/UnauthorizedPage";
import PublicRoute from "helpers/PublicRoute";
import PrivateRoute from "helpers/PrivateRoute";
import { setAuthorizationHeader } from "helpers/axiosCliente";

import AuthActions from "store/actions/AuthActions";
import UserActions from "store/actions/UserActions";

import { verifyPermissions } from "helpers/permission";
import PermissionActions from "store/actions/PermissionsActions";

const Login = lazy(() => import("./pages/Login"));
const PasswordRecovery = lazy(() => import("./pages/PasswordRecovery"));
const PasswordReset = lazy(() => import("./pages/PasswordReset"));

const Reports = lazy(() => import("./pages/Reports"));

const Integrations = lazy(() => import("./pages/Integrations"));

const Transactions = lazy(() => import("./pages/Transactions"));

const Members = lazy(() => import("./pages/MembersPages/Members"));
const MembersCreate = lazy(() => import("./pages/MembersPages/MembersCreate"));
const MembersDetail = lazy(() => import("./pages/MembersPages/MembersDetail"));

const Profile = lazy(() => import("./pages/ProfilesPages/Profile"));
const ProfileCreate = lazy(() => import("./pages/ProfilesPages/ProfileCreate"));
const ProfileDetail = lazy(() => import("./pages/ProfilesPages/ProfileDetail"));

const Zones = lazy(() => import("./pages/ZonesPages/Zones"));
const ZonesCreate = lazy(() => import("./pages/ZonesPages/ZonesCreate"));
const ZonesDetail = lazy(() => import("./pages/ZonesPages/ZonesDetail"));

const BranchOffices = lazy(() =>
  import("./pages/BranchOfficesPages/BranchOffices")
);
const BranchOfficesCreate = lazy(() =>
  import("./pages/BranchOfficesPages/BranchOfficesCreate")
);
const BranchOfficesDetail = lazy(() =>
  import("./pages/BranchOfficesPages/BranchOfficesDetail")
);

const Products = lazy(() => import("./pages/ProductsPages/Products"));
const ProductsCreate = lazy(() =>
  import("./pages/ProductsPages/ProductsCreate")
);
const ProductsDetail = lazy(() =>
  import("./pages/ProductsPages/ProductsDetail")
);

const Clients = lazy(() => import("./pages/ClientsPages/Clients"));
const ClientsEdit = lazy(() => import("./pages/ClientsPages/ClientsEdit"));
const ClientsCreate = lazy(() => import("./pages/ClientsPages/ClientsCreate"));
const ClientsDetail = lazy(() => import("./pages/ClientsPages/ClientsDetail"));

const PaymentLinks = lazy(() => import("./pages/LinksPages/PaymentLinks"));
const PaymentLinksCreate = lazy(() =>
  import("./pages/LinksPages/PaymentLinksCreate")
);
const PaymentLinksConfig = lazy(() =>
  import("./pages/LinksPages/PaymentLinksConfig")
);
const PaymentLinksEdit = lazy(() =>
  import("./pages/LinksPages/PaymentLinksEdit")
);
const PaymentLinksDetail = lazy(() =>
  import("./pages/LinksPages/PaymentLinksDetail")
);
const PaymentLinksPreview = lazy(() =>
  import("./pages/LinksPages/PaymentLinksPreview")
);
const PaymentLinksCreateMassive = lazy(() =>
  import("./pages/LinksPages/PaymentLinksCreateMassive")
);

const Sells = lazy(() => import("pages/SellsPages/Sells"));
const SaleDetail = lazy(() =>
  import("pages/SellsPages/SellsDetails/SaleDetail")
);
const SellsQrDetails = lazy(() =>
  import("pages/SellsPages/SellsDetails/SellsQrDetails")
);
const SellsMsgDetails = lazy(() =>
  import("pages/SellsPages/SellsDetails/SellsMsgDetails")
);
const SellsSpeiDetails = lazy(() =>
  import("pages/SellsPages/SellsDetails/SellsSpeiDetails")
);
const SellsCreditDetails = lazy(() =>
  import("pages/SellsPages/SellsDetails/SellsCreditDetails")
);
const SellsEcommerceDetails = lazy(() =>
  import("pages/SellsPages/SellsDetails/SellsEcommerceDetails")
);

const Conciliation = lazy(() =>
  import("pages/ConciliationsPages/Conciliation")
);
const ConciliationDetail = lazy(() =>
  import("pages/ConciliationsPages/ConciliationDetail")
);

const Refund = lazy(() => import("pages/Refund"));

const Invoices = lazy(() => import("./pages/InvoicesPages/Invoices/Invoices"));

const Chargebacks = lazy(() => import("./pages/ChargebacksPage/Chargebacks"));
const ChargebackDetail = lazy(() =>
  import("./pages/ChargebacksPage/ChargebackDetail")
);

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const localStorageToken = JSON.parse(localStorage.getItem("accessToken"));
    const localStorageUser = JSON.parse(localStorage.getItem("infoUser"));

    setAuthorizationHeader(localStorageToken);

    if (localStorageToken) dispatch(AuthActions.setToken(localStorageToken));
    if (localStorageUser)
      dispatch(
        UserActions.setUser({
          account: localStorageUser?.account,
          business: localStorageUser?.business,
          permission: localStorageUser?.permission,
        })
      );

    const productsPermission = verifyPermissions(
      localStorageUser?.permission,
      "Productos"
    );
    dispatch(PermissionActions.permissionsProducts(productsPermission));

    const zonesPermission = verifyPermissions(
      localStorageUser?.permission,
      "Regiones"
    );
    dispatch(PermissionActions.permissionsZones(zonesPermission));

    const branchesPermission = verifyPermissions(
      localStorageUser?.permission,
      "Sucursales"
    );
    dispatch(PermissionActions.permissionsBranches(branchesPermission));

    const salesPermission = verifyPermissions(
      localStorageUser?.permission,
      "Pagos"
    );
    dispatch(PermissionActions.permissionsSales(salesPermission));

    const paymentLinksPermission = verifyPermissions(
      localStorageUser?.permission,
      "Links de pago"
    );
    dispatch(PermissionActions.permissionsPaymentLinks(paymentLinksPermission));

    const clientsPermission = verifyPermissions(
      localStorageUser?.permission,
      "Clientes"
    );
    dispatch(PermissionActions.permissionsClients(clientsPermission));

    const refundsPermission = verifyPermissions(
      localStorageUser?.permission,
      "Devoluciones"
    );
    dispatch(PermissionActions.permissionsRefunds(refundsPermission));

    const chargebacksPermission = verifyPermissions(
      localStorageUser?.permission,
      "Contracargos"
    );
    dispatch(PermissionActions.permissionsChargebacks(chargebacksPermission));

    const conciliationsPermission = verifyPermissions(
      localStorageUser?.permission,
      "Conciliaciones"
    );
    dispatch(
      PermissionActions.permissionsConciliations(conciliationsPermission)
    );

    if (
      localStorageToken === null ||
      localStorageToken === undefined ||
      localStorageUser === null ||
      localStorageUser === undefined
    ) {
      dispatch(AuthActions.removeToken);
      localStorage.clear();
      history.push("/login");
    }

    // eslint-disable-next-line
  }, []);

  const userPermissionName = useSelector(
    (state) => state?.userReducer?.permission?.super
  );
  const isSuperAdmin = userPermissionName === 1;

  const havePermissionCreateProduct =
    useSelector(
      (state) => state?.permissionsReducer?.permissionProducts?.permissionCreate
    ) || isSuperAdmin;
  const havePermissionCreatePaymentLinks =
    useSelector(
      (state) =>
        state?.permissionsReducer?.permissionPaymentLinks?.permissionCreate
    ) || isSuperAdmin;
  const havePermissionEditPaymentLinks =
    useSelector(
      (state) =>
        state?.permissionsReducer?.permissionPaymentLinks?.permissionUpdate
    ) || isSuperAdmin;
  const havePermissionCreateBranch =
    useSelector(
      (state) => state?.permissionsReducer?.permissionBranches?.permissionCreate
    ) || isSuperAdmin;
  const havePermissionCreateZone =
    useSelector(
      (state) => state?.permissionsReducer?.permissionZones?.permissionCreate
    ) || isSuperAdmin;
  const havePermissionCreateClient =
    useSelector(
      (state) => state?.permissionsReducer?.permissionClients?.permissionCreate
    ) || isSuperAdmin;
  const havePermissionUpdateClient =
    useSelector(
      (state) => state?.permissionsReducer?.permissionClients?.permissionUpdate
    ) || isSuperAdmin;
  const havePermissionReadSales =
    useSelector((state) => state?.permissionsReducer?.permissionSales) ||
    isSuperAdmin;
  const havePermissionReadChargebacks =
    useSelector((state) => state?.permissionsReducer?.permissionChargebacks) ||
    isSuperAdmin;
  const havePermissionReadConciliations =
    useSelector(
      (state) => state?.permissionsReducer?.permissionConciliations
    ) || isSuperAdmin;

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Redirect exact path="/" to="/login" />

        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute
          exact
          path="/password-recovery"
          component={PasswordRecovery}
        />
        <PublicRoute
          exact
          path={
            location.pathname.split("/")[1] === "password-reset"
              ? location.pathname
              : "-*-+++"
          }
          component={PasswordReset}
        />
        <PrivateRoute
          path="/"
          component={() => (
            <AppLayout>
              <Suspense fallback={<Loader />}>
                <Switch>
                  <PrivateRoute
                    exact
                    path="/"
                    render={() => <Redirect to="/dashboard" />}
                  />

                  <PrivateRoute
                    exact
                    path="/transactions"
                    component={Transactions}
                  />

                  <PrivateRoute
                    path="/dashboard"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/dashboard"
                          render={() => <Redirect to="/dashboard/all" />}
                        />
                        <PrivateRoute
                          path="/dashboard/all"
                          component={Reports}
                        />
                      </Suspense>
                    )}
                  />

                  {/* Integrations */}
                  {/* <PrivateRoute path="/integrations" component={() => (
									<Suspense fallback={<Loader />}>
										<PrivateRoute exact path="/integrations" render={() => <Redirect to="/integrations/all" />} />
										<PrivateRoute path="/integrations/all" component={Integrations} />
									</Suspense>
								)} /> */}

                  {/* Members */}
                  {/*<PrivateRoute path="/members" component={() => (
									<Suspense fallback={<Loader />}>
										<PrivateRoute exact path="/members" render={() => <Redirect to="/members/all" />} />
										<PrivateRoute path="/members/all" component={Members} />
										<PrivateRoute path="/members/create" component={MembersCreate} />
										<PrivateRoute path="/members/detail/:id" component={MembersDetail} />
									</Suspense>
								)} />*/}

                  {/* Profiles */}
                  <PrivateRoute
                    path="/profile"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/profile"
                          render={() => <Redirect to="/profile/all" />}
                        />
                        <PrivateRoute path="/profile/all" component={Profile} />
                        {/*<PrivateRoute path="/profile/create" component={ProfileCreate} />
								<PrivateRoute path="/profile/detail/:id" component={ProfileDetail} />*/}
                      </Suspense>
                    )}
                  />

                  {/* Products */}

                  <PrivateRoute
                    path="/products"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/products"
                          render={() => <Redirect to="/products/all" />}
                        />
                        <PrivateRoute
                          path="/products/all"
                          component={Products}
                        />
                        <PrivateRoute
                          path="/products/create"
                          component={
                            havePermissionCreateProduct
                              ? ProductsCreate
                              : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/products/detail/:id"
                          component={ProductsDetail}
                        />
                      </Suspense>
                    )}
                  />

                  {/* Payment links */}
                  <PrivateRoute
                    path="/payment-links"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/payment-links"
                          render={() => <Redirect to="/payment-links/all" />}
                        />
                        <PrivateRoute
                          path="/payment-links/all"
                          component={PaymentLinks}
                        />
                        {/* <PrivateRoute path="/payment-links/masive" component={PaymentLinksCreateMassive} /> */}
                        <PrivateRoute
                          path="/payment-links/create"
                          component={
                            havePermissionCreatePaymentLinks
                              ? PaymentLinksCreate
                              : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/payment-links/config"
                          component={
                            havePermissionEditPaymentLinks
                              ? PaymentLinksConfig
                              : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/payment-links/edit/:hash"
                          component={
                            havePermissionEditPaymentLinks
                              ? PaymentLinksEdit
                              : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/payment-links/detail/:hash"
                          component={PaymentLinksDetail}
                        />
                        <PrivateRoute
                          path="/payment-links/preview/:hash"
                          component={PaymentLinksPreview}
                        />
                      </Suspense>
                    )}
                  />

                  {/* Invoices */}
                  {/*<PrivateRoute path="/invoices" component={() => (
									<Suspense fallback={<Loader />}>
										<PrivateRoute exact path="/invoices" render={() => <Redirect to="/invoices/all" />} />
										<PrivateRoute path="/invoices/all" component={Invoices} />
									</Suspense>
								)} />*/}

                  {/* Branch offices */}
                  <PrivateRoute
                    path="/branch-offices"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/branch-offices"
                          render={() => <Redirect to="/branch-offices/all" />}
                        />
                        <PrivateRoute
                          path="/branch-offices/all"
                          component={BranchOffices}
                        />
                        <PrivateRoute
                          path="/branch-offices/create"
                          component={
                            havePermissionCreateBranch
                              ? BranchOfficesCreate
                              : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/branch-offices/detail/:id"
                          component={BranchOfficesDetail}
                        />
                      </Suspense>
                    )}
                  />

                  {/* Zones */}
                  <PrivateRoute
                    path="/zones"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/zones"
                          render={() => <Redirect to="/zones/all" />}
                        />
                        <PrivateRoute path="/zones/all" component={Zones} />
                        <PrivateRoute
                          path="/zones/create"
                          component={
                            havePermissionCreateZone
                              ? ZonesCreate
                              : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/zones/detail/:id"
                          component={ZonesDetail}
                        />
                      </Suspense>
                    )}
                  />

                  {/* Clients */}
                  <PrivateRoute
                    path="/clients"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/clients"
                          render={() => <Redirect to="/clients/all" />}
                        />
                        <PrivateRoute path="/clients/all" component={Clients} />
                        <PrivateRoute
                          path="/clients/create"
                          component={
                            havePermissionCreateClient
                              ? ClientsCreate
                              : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/clients/detail/:id"
                          component={ClientsDetail}
                        />
                        <PrivateRoute
                          path="/clients/edit/:id"
                          component={
                            havePermissionUpdateClient
                              ? ClientsEdit
                              : UnauthorizedPage
                          }
                        />
                      </Suspense>
                    )}
                  />

                  {/* Sells */}
                  <PrivateRoute
                    path="/sells"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/sells"
                          render={() => <Redirect to="/sells/all" />}
                        />
                        <PrivateRoute
                          path="/sells/all"
                          component={
                            havePermissionReadSales ? Sells : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/sells/detail/:id"
                          component={
                            havePermissionReadSales
                              ? SaleDetail
                              : UnauthorizedPage
                          }
                        />
                        {/* <PrivateRoute path="/sells/qr/detail/:id" component={SellsQrDetails} />
										<PrivateRoute path="/sells/msg/detail/:id" component={SellsMsgDetails} />
										<PrivateRoute path="/sells/spei/detail/:id" component={SellsSpeiDetails} />
										<PrivateRoute path="/sells/credit/detail/:id" component={SellsCreditDetails} />
										<PrivateRoute path="/sells/ecommerce/detail/:id" component={SellsEcommerceDetails} /> */}
                      </Suspense>
                    )}
                  />

                  {/* Conciliations */}
                  <PrivateRoute
                    path="/conciliations"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/conciliations"
                          render={() => <Redirect to="/conciliations/all" />}
                        />
                        <PrivateRoute
                          path="/conciliations/all"
                          component={
                            havePermissionReadConciliations
                              ? Conciliation
                              : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/conciliations/detail/:id"
                          component={
                            havePermissionReadConciliations
                              ? ConciliationDetail
                              : UnauthorizedPage
                          }
                        />
                      </Suspense>
                    )}
                  />

                  {/* Devolutions */}
                  <PrivateRoute
                    path="/refund"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/refund"
                          render={() => <Redirect to="/refund/all" />}
                        />
                        <PrivateRoute path="/refund/all" component={Refund} />
                      </Suspense>
                    )}
                  />

                  {/* Chargebacks */}
                  <PrivateRoute
                    path="/chargebacks"
                    component={() => (
                      <Suspense fallback={<Loader />}>
                        <PrivateRoute
                          exact
                          path="/chargebacks"
                          render={() => <Redirect to="/chargebacks/all" />}
                        />
                        <PrivateRoute
                          path="/chargebacks/all"
                          component={
                            havePermissionReadChargebacks
                              ? Chargebacks
                              : UnauthorizedPage
                          }
                        />
                        <PrivateRoute
                          path="/chargebacks/detail/:id"
                          component={
                            havePermissionReadChargebacks
                              ? ChargebackDetail
                              : UnauthorizedPage
                          }
                        />
                      </Suspense>
                    )}
                  />
                </Switch>
              </Suspense>
            </AppLayout>
          )}
        />
        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
};

export default App;

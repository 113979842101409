import {
    CLEAR_STORE
} from "store/types";

const clearStore = () => ({ type: CLEAR_STORE });

const StoreActions = {
    clearStore,
};

export default StoreActions;
import {
    FETCHING_PASSWORD_RECOVERY,
    GET_PASSWORD_RECOVERY,
    ERROR_PASSWORD_RECOVERY
} from "store/types";

const initialState = {
	fetching: undefined,
    success: undefined,
    successMessage: "",
	error: undefined,
	errorMessage: ""
};

const passwordRecoveryReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCHING_PASSWORD_RECOVERY:
            return{
                ...state,
                fetching: action.payload,
            };
        case GET_PASSWORD_RECOVERY:
            return{
                ...state,
                success: action?.payload?.success,
                successMessage: action?.payload?.msg,
                error: false,
                errorMessage: 'Ups! Hubo un error al enviar el correo electrónico, verifique sus correo o intente más tarde.'
            };
        case ERROR_PASSWORD_RECOVERY:
            return {
                ...state,
                error: action?.payload?.error,
                errorMessage: 'Ups! Hubo un error inesperado al enviar el correo electrónico, intente más tarde.',
                fetching: false,
            };
        default:
            return state;
    }
};

export default passwordRecoveryReducer;

import {
    PERMISSIONS_PRODUCTS,
    PERMISSIONS_PAYMENT_LINKS,
    PERMISSIONS_BRANCHES,
    PERMISSIONS_ZONES,
    PERMISSIONS_CLIENTS,
    PERMISSIONS_SALES,
    PERMISSIONS_REFUNDS,
    PERMISSIONS_CHARGEBACKS,
    PERMISSIONS_CONCILIATIONS
} from "store/types";

const permissionsProducts = data => ({
    type: PERMISSIONS_PRODUCTS,
    payload: data,
});

const permissionsPaymentLinks = data => ({
    type: PERMISSIONS_PAYMENT_LINKS,
    payload: data,
})

const permissionsBranches = data => ({
    type: PERMISSIONS_BRANCHES,
    payload: data,
})

const permissionsZones = data => ({
    type: PERMISSIONS_ZONES,
    payload: data,
})

const permissionsClients = data => ({
    type: PERMISSIONS_CLIENTS,
    payload: data,
})

const permissionsSales = data => ({
    type: PERMISSIONS_SALES,
    payload: data,
})

const permissionsRefunds = data => ({
    type: PERMISSIONS_REFUNDS,
    payload: data,
})

const permissionsChargebacks = data => ({
    type: PERMISSIONS_CHARGEBACKS,
    payload: data,
})

const permissionsConciliations = data => ({
    type: PERMISSIONS_CONCILIATIONS,
    payload: data,
})

const PermissionActions = {
    permissionsProducts,
    permissionsPaymentLinks,
    permissionsBranches,
    permissionsZones,
    permissionsClients,
    permissionsSales,
    permissionsRefunds,
    permissionsChargebacks,
    permissionsConciliations
};

export default PermissionActions;

import QrImg from "resources/img/cards/qr.png";
import CardImg from "resources/img/cards/card_logo.png";
import PushImg from "resources/img/cards/codi_push.png";
import SpeiImg from "resources/img/cards/spei.png";
import EcommerceLogo from "resources/img/cards/ecommerceLogo.png";

import amex from "resources/img/paymentsLogos/amex.png";
import visa from "resources/img/paymentsLogos/visa.png";
import mastercard from "resources/img/paymentsLogos/mastercard.png";

export const paymentsGeneral = (obj = {}) => {
    const values = [];

    Object.entries(obj).forEach(([key, value]) => values.push(Number(value)) );

    return values;
}

const cardTypes = {
    "Amex":
        [
            "AMEX"
        ],
    "Mastercard":
        [
            "CLASICA",
            "EGLO//MC",
            "ELITE",
            "FLEXCARD",
            "INSPIRE",
            "INVEX",
            "LIGHT",
            "MASTER CARD",
            "MASTER CARD INTL",
            "MASTERCARD",
            "MC",
            "ORO",
            "PLATINO",
            "SS BLACK",
            "WORLDELITE",
        ],
    "Visa":
        [
            "ADVANCE",
            "CICASH",
            "HEB TRAD",
            "KE BUENA",
            "SPINBYOXXO",
            "VISA",
            "VISA     ,",
            "VISA ELECT",
            "VISA INTERNACIONAL",
            "VISA ORO",
            "VISA------",
            "VISABANCOP",
            "VISACLASIC",
        ],
    "OtherCards":
        [
            "CARNET",
            "CREDITO/PL",
            "DISCOVER",
            "JCB",
            "PRIVADA",
            "UATP",
            "UNIK",
            "UNION PAY",
        ]
}

const findCardType = (cardTypeUsed) => {
    for (const cardType in cardTypes) {
        if (cardTypes[cardType].includes(cardTypeUsed)) {
            return cardType;
        }
    }
    return;
}

export const getPaymentLogo = (paymentType = "") => {
    const cardType = findCardType(paymentType);

    if (cardType) {
        switch (cardType) {
            case "OtherCards":
                return CardImg;

            case "Amex":
                return amex;

            case "Visa":
                return visa;

            case "Mastercard":
                return mastercard;

            default:
                return "";
        }
    } else {
        switch (paymentType) {
            case "codi":
                return PushImg;

            case "spei":
                return SpeiImg;

            case "qr":
                return QrImg;

            case "ecommerce":
                return EcommerceLogo;

            default:
                return "";
        }
    }
};

import {
    FETCHING_PERMANENT_PAYMENTLINK_DETAIL,
    GET_PERMANENT_PAYMENTLINK_DETAIL,
    ERROR_PERMANENT_PAYMENTLINK_DETAIL
} from "store/types";

const initialState = {
    paymentLinkDetail: {},
    fetching: false,
    error: false,
    errorMessage: "",
}

const permanentPaymentLinksDetailReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCHING_PERMANENT_PAYMENTLINK_DETAIL:
            return{
                ...state,
                fetching: action?.payload,
            };
        case GET_PERMANENT_PAYMENTLINK_DETAIL:
            return{
                ...state,
                paymentLinkDetail: action?.payload,
                error: false,
                errorMessage: "",
            };
        case ERROR_PERMANENT_PAYMENTLINK_DETAIL:
            return{
                ...state,
                error: action?.payload?.error,
                errorMessage: "Ups! Hubo un error al obtener el detalle de este link de pago",
                fetching: false,
            };
        default:
            return state;
    }
};

export default permanentPaymentLinksDetailReducer;
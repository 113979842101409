import {
    PERMISSIONS_PRODUCTS,
    PERMISSIONS_PAYMENT_LINKS,
    PERMISSIONS_BRANCHES,
    PERMISSIONS_ZONES,
    PERMISSIONS_CLIENTS,
    PERMISSIONS_SALES,
    PERMISSIONS_REFUNDS,
    PERMISSIONS_CHARGEBACKS,
    PERMISSIONS_CONCILIATIONS
} from "store/types";

const initialState = {
    permissionProducts: {},
    permissionPaymentLinks: {},
    permissionBranches: {},
    permissionZones: {},
    permissionClients: {},
    permissionSales: {},
    permissionRefunds: {},
    permissionChargebacks: {},
    permissionConciliations: {}
};

const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERMISSIONS_PRODUCTS:
            return {
                ...state,
                permissionProducts: action?.payload
            };
        case PERMISSIONS_PAYMENT_LINKS:
            return {
                ...state,
                permissionPaymentLinks: action?.payload
            };
        case PERMISSIONS_BRANCHES:
            return {
                ...state,
                permissionBranches: action?.payload
            };
        case PERMISSIONS_ZONES:
            return {
                ...state,
                permissionZones: action?.payload
            };
        case PERMISSIONS_CLIENTS:
            return {
                ...state,
                permissionClients: action?.payload
            };
        case PERMISSIONS_SALES:
            return {
                ...state,
                permissionSales: action?.payload
            };
        case PERMISSIONS_REFUNDS:
            return {
                ...state,
                permissionRefunds: action?.payload
            };
        case PERMISSIONS_CHARGEBACKS:
            return {
                ...state,
                permissionChargebacks: action?.payload
            };
        case PERMISSIONS_CONCILIATIONS:
            return {
                ...state,
                permissionConciliations: action?.payload
            };

        default:
            return state;
    }
};

export default permissionsReducer;
import {
    Popover,
    OverlayTrigger,
} from 'react-bootstrap';

import { Bars } from 'resources/icons';
import { ROUTES } from 'constants/routes';
import { NavLink } from 'react-router-dom';
import "./styles.scss";


const NavigationBottom = () => {
    
    const popoverFocus = (
        <Popover title="Popover bottom">
            <ul className='menuBottom'>
                { ROUTES.map(route => (
                    <li key={route?.id}>
                        <NavLink to={route?.path} activeClassName='active'>
                            {route?.icon}
                            { route?.name }
                        </NavLink>
                    </li>
                ))}
            </ul>
        </Popover>
    );
    
    return (
        <>
            <OverlayTrigger trigger="focus" placement="top" overlay={popoverFocus}>
                <button type="button" className="wrapButtonBottom">
                    <Bars />
                </button>
            </OverlayTrigger>
        </>
    );
};

export default NavigationBottom;

import {
	SET_REPORTS,
	FETCHING_PAYMENTS_WITH_TERMINALS,
	GET_PAYMENTS_WITH_TERMINALS,
	ERROR_PAYMENTS_WITH_TERMINALS
} from "store/types";

const initialState = {
	reports: {},
	paymentsWithTerminals: {},
	fetching: undefined,
	error: false,
	errorMessage: ""
};

const reportsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_REPORTS:
			return {
                ...state,
				reports: {
					...state.reports,
					...action?.payload
				}
			};

		case FETCHING_PAYMENTS_WITH_TERMINALS:
			return {
				...state,
				fetching: action?.payload,
			};
		case GET_PAYMENTS_WITH_TERMINALS:
			return {
				...state,
				paymentsWithTerminals: action?.payload,
				error: false,
				errorMessage: ""
			};
		case ERROR_PAYMENTS_WITH_TERMINALS:
			return {
				...state,
				error: action?.payload,
				errorMessage: "Ups! Hubo un error al obtener los pagos hechos con terminales",
				fetching: false,
				paymentsWithTerminals: {},
				reports: {},

			};

		default:
			return state;
	}
};

export default reportsReducer;
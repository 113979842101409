import {
    SET_USER,
} from "store/types";

const setUser = data => ({
    type: SET_USER,
    payload: data,
});

const AuthActions = {
    setUser,
};

export default AuthActions;
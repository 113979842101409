import {
    Paycode,
} from 'resources/icons';
import CustomAvatar from 'components/Ui/CustomAvatar';
import MiniCalendar from 'components/Ui/MiniCalendar';

import "./styles.scss";
import NavigationBottom from 'components/Ui/PopoversUI/NavigationBottom';
import LastPaymentsPopOver from 'components/Ui/PopoversUI/LastPaymentsPopOver';

const CustomBarBottom = () => {
    return (
        <ul className="barBottom">
            <li>
                <NavigationBottom />
            </li>
            <li>
                <MiniCalendar />
            </li>
            <li>
                <Paycode/>
            </li>
            <li>
                <LastPaymentsPopOver />
            </li>
            <li>
                <CustomAvatar placementAvatar="top" mobile />
            </li>
        </ul>
    )
}

export default CustomBarBottom

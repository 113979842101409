import React from 'react';

import "./styles.scss";

const Loader = ({ height = 16, width = 16 }) => {
    return (
        <div className="loader__wrap">
            <div
                className="spinner"
                style={{ height: `${height}px`, width: `${width}px` }}
            />
        </div>
    )
}

export default Loader

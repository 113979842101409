import "./styles.scss";

const MiniCalendar = () => {

    const dayNow = new Date();

    return (
        <div className="miniCalendar">
            <header className="miniCalendar__header">
                <div className="miniCalendar__header-orificeL" />
                <div className="miniCalendar__header-orificeR" />
            </header>
            <p className="miniCalendar__body">{dayNow.getDate()}</p>
        </div>
    )
}

export default MiniCalendar
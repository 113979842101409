import React from "react";

const ProductTag = ({ className = "", style = {}, ...rest }) => (
	<svg
		style={{
			height : "1em",
			width  : "1em",
			...style,
		}}
		className={className}
		viewBox="0 0 13 12.97"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path fill="currentColor" d="M12.87,3.84c0,.77.09,1.53.13,2.3a.37.37,0,0,1-.12.31L6.79,12.54a1.63,1.63,0,0,1-2.22,0L.5,8.47a1.61,1.61,0,0,1,0-2.32l6.06-6A.44.44,0,0,1,6.84,0l3.59.18c.38,0,.76,0,1.14.07A1.34,1.34,0,0,1,12.74,1.5C12.78,2.28,12.83,3.06,12.87,3.84Zm-.55,0h0c0-.1,0-.2,0-.29,0-.64-.06-1.27-.1-1.91a.81.81,0,0,0-.84-.81l-.77,0L7,.54a.32.32,0,0,0-.26.1L.93,6.45a1.1,1.1,0,0,0,0,1.69l4,3.95a1.07,1.07,0,0,0,1.56,0L9.76,8.78l2.58-2.59A.28.28,0,0,0,12.43,6C12.4,5.28,12.36,4.53,12.32,3.79Z" />
		<path fill="currentColor" d="M11.09,3.31A1.43,1.43,0,1,1,9.66,1.88,1.42,1.42,0,0,1,11.09,3.31Zm-.53,0a.9.9,0,0,0-.89-.89.91.91,0,0,0-.89.87.91.91,0,0,0,.89.9A.9.9,0,0,0,10.56,3.3Z" />
	</svg>
);

export default ProductTag;
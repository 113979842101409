const getLettersName = (
    name = "",
    lastName = "",
) => {

    const letterName = name.slice(0, 1);
    const letterLastName = lastName.slice(0, 1);

    return letterName.toUpperCase() + letterLastName.toUpperCase();

}

export default getLettersName;

import {
	FETCHING_CLIENTS,
	GET_CLIENTS,
	ERROR_CLIENTS,
	SET_PAGE_CLIENT,
} from "store/types";

const initialState = {
	clientPage: 1,
	clients: {},
	fetching: undefined,
	error: false,
	errorMessage: ""
};

const clientReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_CLIENTS:
			return {
				...state,
				fetching: action?.payload,
			};
		case GET_CLIENTS:
			return {
				...state,
				clients: action?.payload,
				error: false,
				errorMessage: ""
			};
		case ERROR_CLIENTS:
			return {
				...state,
				error: action?.payload,
				errorMessage: "Ups! Hubo un error al obtener a los clientes",
				fetching: false,
				clients: {}
			};
		case SET_PAGE_CLIENT:
			return {
				...state,
				clientPage: action?.payload
			}
		default:
			return state;
	}
};

export default clientReducer;
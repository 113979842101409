import {
    GET_MEMBERS,
} from "store/types";

const initialState = {};

const membersReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_MEMBERS :
			return {
                ...state,
				members: action?.payload
			};
		default:
			return state;
	}
};

export default membersReducer;
import { connect } from 'react-redux';

import isValidArray from 'helpers/isValidArray';
import currencyFormat from 'helpers/currencyFormat';
import ReportsPayments from 'components/Ui/Skeletons/ReportsPayments';
import { getPaymentLogo } from 'helpers/clientDetailGraph';
import "./styles.scss";

const TotalPayments = ({
    isLoadPage,
    className = "",
    last_payment_codi,
    last_paymentes_crdit,
    last_payments_qr,
    last_payments_spei,
    last_payments
}) => {

    return (
        <>
            { isLoadPage ?
                <>
                    <div className={`payments_last ${className}`}>
                        <h3>Últimos pagos</h3>
                        <ul>
                            {isValidArray(last_payments) && last_payments.map(item => (
                                <li key={item?.id}>
                                    <div className="list">
                                        <figure>
                                            <img src={getPaymentLogo(item?.typepay)} alt="" class="imaaa"/>
                                        </figure>
                                        <div class="text_pading_list">
                                            <p className="list__name">{item?.name}</p>
                                            <p className="list__date">{item?.fecha}</p>
                                        </div>
                                    </div>
                                    <p className="amount-total">{currencyFormat(Number(item?.total))}</p>
                                </li>
                            ))}
                        </ul> 
                    </div>
                </> : <ReportsPayments  />
            }
        </>
    )
};

const mapStateToProps = ({ reportsReducer }) => ({

    last_payments: reportsReducer?.reports?.totalPay?.last_payments ?? "" ,


    last_payment_codi: reportsReducer?.reports?.totalPay?.last_payments?.last_payment_codi ?? "" ,
    last_paymentes_crdit: reportsReducer?.reports?.totalPay?.last_payments?.last_paymentes_crdit ?? "" ,
    last_payments_qr: reportsReducer?.reports?.totalPay?.last_payments?.last_payments_qr ?? "" ,
    last_payments_spei: reportsReducer?.reports?.totalPay?.last_payments?.last_payments_spei ?? "",
})

export default connect(mapStateToProps)(TotalPayments);

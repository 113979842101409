import {
    SET_TOKEN,
    REMOVE_TOKEN,
} from "store/types";

const initialState = {};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TOKEN:
			return {
                ...state,
				...action?.payload,
				loggedIn: true,
			};
		case REMOVE_TOKEN:
			return {}
		default:
			return state;
	}
};

export default authReducer;
const requestAccessToRoute = (modules, locationPathname) => {
    console.log("modules", modules);
    console.log("locationPathname", locationPathname);

    const regexDashboard = /\/dashboard(.+)?/;
    const regexZones = /\/zones(.+)?/;
    const regexBranches = /\/branch-offices(.+)?/;
    const regexProducts = /\/products(.+)?/;
    const regexPeriods = /\/periods(.+)?/;
    const regexSales = /\/sells(.+)?/;
    const regexClients = /\/clients(.+)?/;
    const regexMassiveUploads = /\/massive-uploads(.+)?/;
    const regexProfile = /\/profile(.+)?/;
    const regexAdministrators = /\/administrators(.+)?/;
    const regexPaymentLinks = /\/payment-links(.+)?/;
    const regexReminders = /\/reminders(.+)?/;
    const regexRefund = /\/refund(.+)?/;
    const regexChargebacks = /\/chargebacks(.+)?/;
    const regexConciliations = /\/conciliations(.+)?/;

    const accessDashboard = regexDashboard.test(locationPathname);
    const accessZones = regexZones.test(locationPathname);
    const accessBranches = regexBranches.test(locationPathname);
    const accessProducts = regexProducts.test(locationPathname);
    const accessPeriods = regexPeriods.test(locationPathname);
    const accessSales = regexSales.test(locationPathname);
    const accessClients = regexClients.test(locationPathname);
    const accessMassiveUploads = regexMassiveUploads.test(locationPathname);
    const accessProfile = regexProfile.test(locationPathname);
    const accessAdministrators = regexAdministrators.test(locationPathname);
    const accessPaymentLinks = regexPaymentLinks.test(locationPathname);
    const accessReminders = regexReminders.test(locationPathname);
    const accessRefund = regexRefund.test(locationPathname);
    const accessChargebacks = regexChargebacks.test(locationPathname);
    const accessConciliations = regexConciliations.test(locationPathname);

    let moduleFromBackend = undefined;

    if (accessDashboard) {
        moduleFromBackend = 'Dashboard'
    } else if (accessZones) {
        moduleFromBackend = 'Regiones'
    } else if (accessBranches) {
        moduleFromBackend = 'Sucursales'
    } else if (accessProducts) {
        moduleFromBackend = 'Productos'
    } else if (accessPeriods) {
        moduleFromBackend = 'Periodos'
    } else if (accessSales) {
        moduleFromBackend = 'Pagos'
    } else if (accessClients) {
        moduleFromBackend = 'Clientes'
    } else if (accessMassiveUploads) {
        moduleFromBackend = 'Cargas Masivas'
    } else if (accessProfile) {
        moduleFromBackend = 'Perfiles'
    } else if (accessAdministrators) {
        moduleFromBackend = 'Administradores'
    } else if (accessPaymentLinks) {
        moduleFromBackend = 'Links de pago'
    } else if (accessReminders) {
        moduleFromBackend = 'Recordatorios'
    } else if (accessRefund) {
        moduleFromBackend = 'Devoluciones'
    } else if (accessChargebacks) {
        moduleFromBackend = 'Contracargos'
    } else if (accessConciliations) {
        moduleFromBackend = 'Conciliaciones'
    }

    console.log("moduleFromBackend", moduleFromBackend);

    const hadAccess = modules?.find(module => {
        console.log("module", module);
        console.log("moduleFromBackend", moduleFromBackend);
        return module.modules_name === moduleFromBackend;
    });
    console.log("hadAccess", hadAccess);

    return hadAccess === undefined ? false : true;
}

export default requestAccessToRoute;
import {
    FETCHING_CHARGEBACKS,
    GET_CHARGEBACKS,
    ERROR_CHARGEBACKS,
    SET_PAGE_CHARGEBACKS,
    FETCHING_CHARGEBACK_DETAIL,
    GET_CHARGEBACK_DETAIL,
    ERROR_CHARGEBACK_DETAIL
} from "store/types";

const initialState = {
    chargebackPage: 1,
    chargebacks: {},
    fetching: undefined,
    error: false,
    errorMessage: "",

    chargebackDetail: {},
    fetchingChargebackDetail: undefined,
    errorChargebackDetail: false,
    errorMessageChargebackDetail: ""
};

const chargebacksReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_CHARGEBACKS:
            return {
                ...state,
                fetching: action?.payload,
            };
        case GET_CHARGEBACKS:
            return {
                ...state,
                chargebacks: action?.payload,
                error: false,
                errorMessage: ""
            };
        case ERROR_CHARGEBACKS:
            return {
                ...state,
                error: action?.payload,
                errorMessage: "Ups! Hubo un error al obtener los contracargos",
                fetching: false,
                chargebacks: {}
            };
        case SET_PAGE_CHARGEBACKS:
            return {
                ...state,
                chargebackPage: action?.payload
            };

        case FETCHING_CHARGEBACK_DETAIL:
            return {
                ...state,
                fetchingChargebackDetail: action?.payload,
            };
        case GET_CHARGEBACK_DETAIL:
            return {
                ...state,
                chargebackDetail: action?.payload,
                errorChargebackDetail: false,
                errorMessageChargebackDetail: ""
            };
        case ERROR_CHARGEBACK_DETAIL:
            return {
                ...state,
                errorChargebackDetail: action?.payload,
                errorMessageChargebackDetail: "Ups! Hubo un error al obtener el detalle del contracargo",
                fetchingChargebackDetail: false,
                chargebackDetail: {}
            };
        default:
            return state;
    }
};

export default chargebacksReducer;
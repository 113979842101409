import Box          from '@mui/material/Box';
import Drawer       from '@mui/material/Drawer';
import { useState } from 'react';

import Sidebar from '../Sidebar';
import { Bars } from 'resources/icons';
import "./styles.scss";

const CustomDrawer = () => {

	const [openDrawer, setOpenDrawer] = useState(false);
		
	const toggleDrawer = () => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setOpenDrawer(!openDrawer);
	};
    
	return (
		<div className="customDrawer">
			<button
				className="btn-drawer"
				onClick={toggleDrawer()}
			>
				<Bars />
			</button>
			<Drawer
				anchor="left"
				open={openDrawer}
				onClose={toggleDrawer()}
			>
				<Box
					sx={{ width: 250 }}
					role="presentation"
					onClick={toggleDrawer()}
					onKeyDown={toggleDrawer()}
					className="navWrap"
				>
					<Sidebar idSidebar="sidebarDrawer" />
				</Box>
			</Drawer>
		</div>
	);
}

export default CustomDrawer;

import {
	FETCHING_ZONES,
	GET_ZONES,
	ERROR_ZONES,
	SET_PAGE_ZONES,
	GET_ZONES_SELECT,
} from "store/types";

const initialState = {
	zonePage: 1,
	zones: {},
	fetching: undefined,
	error: false,
	errorMessage: "",
};

const zonesReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_ZONES:
			return {
				...state,
				fetching: action?.payload,
			};
		case GET_ZONES:
			return {
				...state,
				zones: action?.payload,
				error: false,
				errorMessage: "",
			};
		case ERROR_ZONES:
			return {
				...state,
				error: action?.payload,
				errorMessage: "Ups! Hubo un error al obtener las regiones",
				fetching: false,
				zones: {}
			};
		case GET_ZONES_SELECT:
			return {
				...state,
				zonesSelect: action?.payload
			};
		case SET_PAGE_ZONES:
			return {
				...state,
				zonePage: action?.payload
			};
		default:
			return state;
	}
};

export default zonesReducer;
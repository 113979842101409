import React from "react";

const PaymentLinks = ({ className = "", style = {}, ...rest }) => (
	<svg
		style={{
			height : "1em",
			width  : "1em",
			...style,
		}}
		className={className}
		viewBox="0 0 16.51 16.5"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path fill="currentColor" d="M16.5,8.26v7.67a.54.54,0,0,1-.57.57H.59A.56.56,0,0,1,0,15.92V.6A.58.58,0,0,1,.58,0H15.83a.64.64,0,0,1,.54.2.57.57,0,0,1,.14.38Q16.49,4.42,16.5,8.26Zm-.24-4.38H.25v12c0,.32.11.43.44.43H15.84c.32,0,.42-.1.42-.43v-12ZM.26,3.6h16v0c0-1,0-2,0-3A.27.27,0,0,0,16,.28a.78.78,0,0,0-.22,0H.66C.38.26.25.38.25.65V3.46S.26,3.55.26,3.6Z" />
		<path fill="currentColor" d="M5.7,14.93a2.3,2.3,0,0,1-2.07-1.38A2.26,2.26,0,0,1,4,11a12.79,12.79,0,0,1,1-1l.89-.9a2.78,2.78,0,0,1,.8-.55.26.26,0,0,0,.12-.11,2.48,2.48,0,0,1,.55-.8L9,5.89a2.41,2.41,0,1,1,3.39,3.43c-.59.59-1.17,1.19-1.78,1.77a6.07,6.07,0,0,1-.72.49s-.1.06-.12.11a2.58,2.58,0,0,1-.57.82L7.51,14.22A2.44,2.44,0,0,1,5.7,14.93Zm4.24-4.6a.12.12,0,0,0,.05-.05c.57-.56,1.13-1.11,1.68-1.68a1.37,1.37,0,0,0,0-2,1.42,1.42,0,0,0-1.91,0L8.07,8.29A.36.36,0,0,0,8,8.38,2.44,2.44,0,0,1,9.94,10.33ZM8.53,11.75a2.5,2.5,0,0,1-2-2,.73.73,0,0,1-.11.15L4.91,11.45a2.09,2.09,0,0,0-.2.22,1.39,1.39,0,0,0,.2,1.87,1.41,1.41,0,0,0,1.89-.06l1.61-1.61Zm1,0,0,0a2.2,2.2,0,0,1-.36,0,.51.51,0,0,0-.45.2q-.84.87-1.71,1.71a1.52,1.52,0,0,1-1.47.41A1.56,1.56,0,0,1,4.25,13a1.6,1.6,0,0,1,.43-1.63c.56-.57,1.13-1.15,1.71-1.71A1.57,1.57,0,0,1,8,9.22a1.55,1.55,0,0,1,1.11,1.21c0,.09,0,.18.06.29l.36-.12c.13,0,.16-.11.13-.24A2.08,2.08,0,0,0,8.26,8.73a2,2,0,0,0-2.13.43c-.67.61-1.31,1.25-1.92,1.91a2.14,2.14,0,0,0,.11,3,2.18,2.18,0,0,0,3-.05c.57-.58,1.15-1.15,1.71-1.73C9.22,12.14,9.36,11.94,9.51,11.76ZM7.3,9.41l-.39.14c-.08,0-.1.08-.08.17a2.08,2.08,0,0,0,1.4,1.67A2,2,0,0,0,10.38,11c.67-.62,1.32-1.27,1.94-1.94a2.11,2.11,0,0,0-.1-3,2.14,2.14,0,0,0-3-.06C8.61,6.62,8,7.28,7.35,7.92A5.49,5.49,0,0,0,7,8.4a1.78,1.78,0,0,1,.36,0,.58.58,0,0,0,.52-.23C8.42,7.56,9,7,9.53,6.47a1.63,1.63,0,0,1,2.72.68,1.53,1.53,0,0,1-.39,1.62c-.57.6-1.16,1.18-1.76,1.76a1.62,1.62,0,0,1-2.56-.42A4.1,4.1,0,0,1,7.3,9.41Zm.3,0A1.33,1.33,0,0,0,8.9,10.7,1.33,1.33,0,0,0,7.6,9.42Z" />
		<path fill="currentColor" d="M4.7,2.12H1.54c-.1,0-.23,0-.23-.13s.13-.12.23-.12H7.88s.09,0,.12,0,.1.08.1.13a.17.17,0,0,1-.1.11s-.09,0-.13,0Z" />
		<path fill="currentColor" d="M14.41,1.19a.81.81,0,0,1,.81.8.82.82,0,0,1-.8.8A.79.79,0,0,1,13.61,2,.8.8,0,0,1,14.41,1.19Zm0,1.35A.55.55,0,0,0,15,2a.55.55,0,0,0-1.1,0A.56.56,0,0,0,14.41,2.54Z" />
		<path fill="currentColor" d="M12.23,2.79A.8.8,0,1,1,13,2,.8.8,0,0,1,12.23,2.79Zm0-1.34a.53.53,0,0,0-.55.54.55.55,0,0,0,.54.55A.55.55,0,0,0,12.78,2,.54.54,0,0,0,12.24,1.45Z" />
		<path fill="currentColor" d="M10.86,2a.8.8,0,0,1-.81.8.8.8,0,1,1,.81-.8ZM10.6,2A.55.55,0,0,0,9.51,2,.55.55,0,1,0,10.6,2Z"/>
	</svg>
);

export default PaymentLinks;
import {
    FETCHING_SALE_DETAIL,
    GET_SALE_DETAIL,
    ERROR_SALE_DETAIL
} from "store/types";

const initialState = {
    saleDetail: {},
    fetching: undefined,
    error: false,
    errorMessage: ""
};

const saleDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_SALE_DETAIL:
            return {
                ...state,
                fetching: action?.payload,
            };
        case GET_SALE_DETAIL:
            return {
                ...state,
                saleDetail: action?.payload,
                error: false,
                errorMessage: ""
            };
        case ERROR_SALE_DETAIL:
            return {
                ...state,
                error: action?.payload,
                errorMessage: "Ups! Hubo un error al obtener el detalle de la venta",
                fetching: false,
                saleDetail: {}
            };
        default:
            return state;
    }
};

export default saleDetailReducer;

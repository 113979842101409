import {
    Popover,
    OverlayTrigger,
} from 'react-bootstrap';

import { Dollar } from 'resources/icons';
import TotalPayments from 'components/layout/TotalPayments';
import "./styles.scss";

const NavigationBottom = () => {
    
    const popoverFocus = (
        <Popover title="Popover bottom">
            <TotalPayments
                isLoadPage
                className="wrapLastPaymentPopver"
            />
        </Popover>
    );
    
    return (
        <>
            <OverlayTrigger trigger="focus" placement="top" overlay={popoverFocus}>
                <button type="button" className="wrapButtonBottom">
                    <Dollar />
                </button>
            </OverlayTrigger>
        </>
    );
};

export default NavigationBottom;

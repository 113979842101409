import {
    Route,
    Redirect,
} from "react-router-dom";
import { connect } from 'react-redux';

const PublicRoute = ({
    loggedIn,
    lastRouteVisited,
    component : Component,
    render    : RenderComponent,
    ...rest
}) => {

    if(loggedIn && !lastRouteVisited) {
        return <Redirect to="/dashboard" />

    } else if(loggedIn && lastRouteVisited) {
        return <Redirect to={lastRouteVisited} />

    } else {
        return <Route component={Component} {...rest} />
    }
}

const mapStateToProps = ({ authReducer, routesReducer }) => ({
    loggedIn: authReducer?.loggedIn ?? false,
    lastRouteVisited: routesReducer?.lastRouteVisted ?? false,
});

export default connect(mapStateToProps)(PublicRoute);

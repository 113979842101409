import axiosClient from "helpers/axiosCliente";
import AuthActions from "store/actions/AuthActions";
import UserActions from "store/actions/UserActions";
import { setAuthorizationHeader } from 'helpers/axiosCliente';

import { verifyPermissions } from 'helpers/permission';
import PermissionActions from 'store/actions/PermissionsActions';

const login = (user, callback) => async (dispatch) => {

    try {

        const { data: dataToken } = await axiosClient.post("Api/v2/auth/internal/login", user);

        if (dataToken?.access_token) {
            setAuthorizationHeader(dataToken);

            const { data } = await axiosClient.get("Api/v2/internal/token/me");

            dispatch(AuthActions.setToken(dataToken));
            dispatch(UserActions.setUser({ account: data?.account, business: data?.data?.business, permission: data?.permission }));

            const productsPermission = verifyPermissions(data?.permission, "Productos");
			dispatch(PermissionActions.permissionsProducts(productsPermission));

            const zonesPermission = verifyPermissions(data?.permission, "Regiones");
			dispatch(PermissionActions.permissionsZones(zonesPermission));

            const branchesPermission = verifyPermissions(data?.permission, "Sucursales");
			dispatch(PermissionActions.permissionsBranches(branchesPermission));

            const salesPermission = verifyPermissions(data?.permission, "Pagos");
			dispatch(PermissionActions.permissionsSales(salesPermission));

            const paymentLinksPermission = verifyPermissions(data?.permission, "Links de pago");
			dispatch(PermissionActions.permissionsPaymentLinks(paymentLinksPermission));

            const clientsPermission = verifyPermissions(data?.permission, "Clientes");
			dispatch(PermissionActions.permissionsClients(clientsPermission));

            const refundsPermission = verifyPermissions(data?.permission, "Devoluciones");
			dispatch(PermissionActions.permissionsRefunds(refundsPermission));

            const chargebacksPermission = verifyPermissions(data?.permission, "Contracargos");
            dispatch(PermissionActions.permissionsChargebacks(chargebacksPermission));

            const conciliationsPermission = verifyPermissions(data?.permission, "Conciliaciones");
            dispatch(PermissionActions.permissionsConciliations(conciliationsPermission));

            localStorage.setItem('infoUser', JSON.stringify({  account: data?.account, business: data?.data?.business, permission: data?.permission }));

            if (data) {
                localStorage.setItem('accessToken', JSON.stringify(dataToken));
            }
        }

    } catch (err) {
        console.log("[login]", err);

        if (err?.response?.data?.error || err?.response?.status === 401) {
            callback(err?.response?.data);
        }
    }
};

const refreshToken = callback => async (dispatch, getState) => {

    try {

        const { data: dataToken } = await axiosClient.post("Api/v2/auth/actualizar_token");

        if (dataToken) {
            callback(true)
            setAuthorizationHeader(dataToken);
            dispatch(AuthActions.setToken(dataToken));
            localStorage.setItem('accessToken', JSON.stringify(dataToken));
        } else {
            callback(false);
        }

    } catch (err) {
        callback(false);
        console.log("[refresh_token]", err?.response);
    }

};

const AuthServices = {
    login,
    refreshToken,
};

export default AuthServices;
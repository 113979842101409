import {
	FETCHING_PRODUCTS,
	GET_PRODUCTS,
	ERROR_PRODUCTS,
	SET_PAGE_PRODUCT,
} from "store/types";

const initialState = {
	productPage: 1,
	products: {},
	fetching: undefined,
	error: false,
	errorMessage: "",
};

const productsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCHING_PRODUCTS:
			return {
				...state,
				fetching: action?.payload,
			};
		case GET_PRODUCTS:
			return {
				...state,
				products: action?.payload,
				error: false,
				errorMessage: "",
			};
		case ERROR_PRODUCTS:
			return {
				...state,
				error: action?.payload,
				errorMessage: "Ups! Hubo un error al obtener los productos",
				fetching: false,
				products: {}
			};
		case SET_PAGE_PRODUCT:
			return {
				...state,
				productPage: action?.payload
			};
		default:
			return state;
	}
};

export default productsReducer;
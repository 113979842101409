import "./styles.scss";

const UnauthorizedPage = (permissions) => {
  return (
    permissions !== undefined &&
    <div className='container-unauthorizedpage'>
      <div>
        <h1>Acceso No Autorizado</h1>
        <p>No tienes permiso para acceder a esta página.</p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;

import isValidArray from "./isValidArray";

const setPermissionSuper = (arr = [], moduleName) => {

    return new Promise((resolve, reject) => {
        const newArr = arr.map(a => {

            let newObj = {
                modulesName: a?.modules_name,
                permissionCreate: 1,
                permissionUpdate: 1,
                permissionDelete: 1,
                permissionRead: 1
            }

            return newObj;
        });

        const result = newArr.find(a => a?.modulesName === moduleName);

        resolve(result)
    });
}

export const verifyPermissions = (arrPermissions = [], moduleName, permissionsSuper = []) => {

    if (typeof (arrPermissions) === "object") {

        if (arrPermissions?.super === 1) {

            const setSuperPermission = setPermissionSuper(permissionsSuper, moduleName)

            return setSuperPermission;
        }
    }

    const verifyModule = isValidArray(arrPermissions) ? arrPermissions.find(a => a?.modulesName === moduleName) : null;

    return verifyModule
};

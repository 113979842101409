import {
	SET_TOKEN_API,
	SET_ACCESS_TOKEN,
	GET_PAYMENT_INFO,
} from "store/types";

const initialState = {
	token_api: "",
	access_token: "",
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_TOKEN_API:
			return {
                ...state,
				token_api: action?.payload?.token_api,
			};
		case SET_ACCESS_TOKEN:
			return {
				...state,
				access_token: action?.payload?.access_token
			}
		case GET_PAYMENT_INFO:
			return {
				...state,
				payment_info: action?.payload?.data
			}

		default:
			return state;
	}
};

export default authReducer;
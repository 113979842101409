import ContentLoader from "react-content-loader";

const ReportsPayments = () => {

  return (
        <ContentLoader 
            speed={2}
            width="100%"
            height="100%"
            viewBox="0 0 400 350"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
            <rect x="0" y="40" rx="3" ry="3" width="250" height="20" />
            <rect x="0" y="80" rx="3" ry="3" width="250" height="20" />
            <rect x="0" y="120" rx="3" ry="3" width="250" height="20" />

            <rect x="0" y="200" rx="3" ry="3" width="150" height="20" />
            <rect x="0" y="240" rx="3" ry="3" width="250" height="20" />
            <rect x="0" y="280" rx="3" ry="3" width="250" height="20" />
            <rect x="0" y="320" rx="3" ry="3" width="250" height="20" />
        </ContentLoader>
    )
}

export default ReportsPayments;
import React from "react";

const Clients = ({ className = "", style = {}, ...rest }) => (
	<svg
		style={{
			height : "1em",
			width  : "1em",
			...style,
		}}
		className={className}
		viewBox="0 0 20.1 14.88"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path fill="currentColor" d="M12.05,14.54a4,4,0,0,1,3.71-3.46,4.08,4.08,0,0,1,4.34,3.46Zm.61-.51h6.78a3.52,3.52,0,0,0-3.34-2.47A3.61,3.61,0,0,0,12.66,14Z" />
		<path fill="currentColor" d="M0,9.18A4.11,4.11,0,0,1,4.11,5.7a4.11,4.11,0,0,1,4,3.48Zm7.41-.49A3.53,3.53,0,0,0,4,6.22,3.47,3.47,0,0,0,.64,8.69Z" />
		<path fill="currentColor" d="M6.47,2.82a2.44,2.44,0,1,1-4.87,0,2.44,2.44,0,1,1,4.87,0ZM2.1,2.8A1.94,1.94,0,1,0,6,2.85a1.94,1.94,0,0,0-1.93-2A1.93,1.93,0,0,0,2.1,2.8Z" />
		<path fill="currentColor" d="M18.5,8.19a2.44,2.44,0,1,1-4.87,0,2.44,2.44,0,1,1,4.87,0Zm-.49,0a1.95,1.95,0,1,0-1.94,2A1.95,1.95,0,0,0,18,8.19Z" />
		<path fill="currentColor" d="M7.86.89,7.71.45a7.36,7.36,0,0,1,9.38,4.33h-.43c-.05,0-.1-.08-.13-.13A6.93,6.93,0,0,0,7.86.89Z" />
		<path fill="currentColor" d="M10.41,14.87A7.38,7.38,0,0,1,3.8,11.24L4.19,11c.23.31.43.61.67.89a6.84,6.84,0,0,0,4.36,2.43,5.76,5.76,0,0,0,1,.06c.14,0,.19,0,.17.18S10.41,14.77,10.41,14.87Z" />
	</svg>
);

export default Clients;
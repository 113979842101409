import {
    GET_EJECUTIVES,
} from "store/types";

const initialState = {};

const ejecutivesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_EJECUTIVES :
			return {
                ...state,
				executives: action?.payload
			};
		default:
			return state;
	}
};

export default ejecutivesReducer;
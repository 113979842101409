import { Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import bindAll from "./bindAll";
import AuthActions from 'store/actions/AuthActions';
import RouteActions from 'store/actions/RouteActions';
import AuthServices from "store/services/AuthServices";
import { responseInterceptorUnauthorized } from "./axiosCliente";

import UnauthorizedPage from 'pages/UnauthorizedPage';
import requestAccessToRoute from './accessToRoutes';

const PrivateRoute = ({
	loggedIn,
	accessToken,
	authActions,
	routeActions,
	lastRouteVisited,
	authServices,
	component: Component,
	render: RenderComponent,
	modules,
	permissions,
	isLite,
	...rest
}) => {
	const location = useLocation();
	const locationPathname = location.pathname;

	const ComponentToRender = () => {

		const checkToken = () => {
			responseInterceptorUnauthorized(authServices, authActions);

			// If the user modifies the localStorage, the localStorage is cleared and the session is closed.
			if (window.localStorage) {
				window.addEventListener('storage', event => {
					localStorage.clear();
					window.location.reload();
				}, false);
			}
		};

		checkToken();

		// Show component
		return RenderComponent
			? <RenderComponent />
			: <Component />;
	};

	const requireAuth = (componentToRender) => {

		const userHasAccess = requestAccessToRoute(modules, locationPathname);

		const localStorageInfoUser = localStorage.getItem("infoUser");

		if (userHasAccess) {
			return componentToRender;
		} else if (isLite && !userHasAccess) {
			window.location.href = "/sells";
			return;
		} else if (!loggedIn && localStorageInfoUser === null) {
			window.location.href = "/login";
			return;
		}

		return () => UnauthorizedPage(permissions);
	}

	return (
		<Route
			{...rest}
			component={requireAuth(ComponentToRender)}
		/>
	);
};

const mapStateToProps = ({ authReducer, routesReducer, userReducer }) => ({
	loggedIn: authReducer?.loggedIn ?? false,
	accessToken: authReducer?.access_token ?? "",
	lastRouteVisited: routesReducer?.lastRouteVisted ?? false,
	modules: userReducer?.business?.modules ?? undefined,
	permissions: userReducer?.permission ?? undefined,
	isLite: userReducer?.account?.lite ?? false
});

const mapDispatchToProps = bindAll({ AuthActions, RouteActions, AuthServices });

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

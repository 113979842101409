import {
    BranchOffices,
    Clients,
    Conciliation,
    Graphics,
    Integrations,
    LinksIcon,
    Load,
    PaymentLinks,
    ProductTag,
    Sells,
} from 'resources/icons';
import { IoReceiptOutline } from 'react-icons/io5'
import { IoMapOutline } from 'react-icons/io5'

export const ROUTES = [
    {
        id: 1,
        path: "/dashboard",
        name: "Dashboard",
        icon: <Graphics />
    },
    {
        id: 2,
        path: "/zones",
        name: "Regiones",
        icon: <IoMapOutline />
    },
    {
        id: 12,
        path: "/branch-offices",
        name: "Sucursales",
        icon: <BranchOffices />
    },
    {
        id: 3,
        path: "/products",
        name: "Productos",
        icon: <ProductTag />
    },
    {
        id: 4,
        path: "/sells",
        name: "Ventas",
        icon: <Sells />
    },
    {
        id: 5,
        path: "/conciliations",
        name: "Conciliaciones",
        icon: <Conciliation />
    },
    {
        id: 6,
        path: "/payment-links",
        name: "Links de pago",
        icon: <LinksIcon />
    },
    {
        id: 7,
        path: "/refund",
        name: "Devoluciones",
        icon: <PaymentLinks />
    },
    {
        id: 8,
        path: "/clients",
        name: "Clientes",
        icon: <Clients />
    },
    /* {
        id: 9,
        path: "/integrations",
        name: "Integraciones",
        icon: <Integrations />
    } */ 
    // {
    //     id: 10,
    //     path: "/massive-uploads",
    //     name: "Cargas masivas",
    //     icon: <Load />
    // },
    /*{
        id: 11,
        path: "/invoices",
        name: "Facturas",
        icon: <IoReceiptOutline />
    },*/
];
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import {
    AngleRight,
    BranchOffices,
    Clients,
    Graphics,
    Integrations,
    PaymentLinks,
    ProductTag,
    Sells,
    LinksIcon,
    Conciliation
} from 'resources/icons';
import { IoReceiptOutline, IoMapOutline, IoReturnDownBackSharp } from 'react-icons/io5'
import { FaRegCreditCard } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import Logo from "resources/img/logo/paycode.png";
import "./styles.scss";

const Sidebar = ({ idSidebar, idSidebarBranch, permissions, permissionsSuper }) => {
    const dispatch =  useDispatch();

    const { pathname } = useLocation()

    const sellsNav = (pathname === "/sells/all") || (pathname === "/conciliations/all");
    const zonesNav = (pathname === "/zones/all") || (pathname === "/branch-offices/all");

    const isLite = useSelector(state => state?.userReducer?.account?.lite);
    const isTpvUser = useSelector(state => state?.userReducer?.permission?.name) === 'TPV User';

    const hideDashboard = isLite || isTpvUser;

    const havePermissionReadProducts = useSelector(state => state?.permissionsReducer?.permissionProducts);
	const havePermissionReadZones = useSelector(state => state?.permissionsReducer?.permissionZones);
	const havePermissionReadBranches = useSelector(state => state?.permissionsReducer?.permissionBranches);
	const havePermissionReadSales = useSelector(state => state?.permissionsReducer?.permissionSales);
	const havePermissionReadPaymentLinks = useSelector(state => state?.permissionsReducer?.permissionPaymentLinks);
	const havePermissionReadClients = useSelector(state => state?.permissionsReducer?.permissionClients);
	const havePermissionReadRefunds = useSelector(state => state?.permissionsReducer?.permissionRefunds);
	const havePermissionReadChargebacks = useSelector(state => state?.permissionsReducer?.permissionChargebacks);
	const havePermissionReadConciliations = useSelector(state => state?.permissionsReducer?.permissionConciliations);

    return (
        <>
            <div className="navWrap__logo">
                <NavLink to="/dashboard">
                    <img src={Logo} alt="logo paycode" />
                </NavLink>
            </div>
            <ul className="navWrap__menu">
                {
                    !hideDashboard &&
                        <li className="navWrap__menu-item">
                            <NavLink to="/dashboard">
                                <Graphics />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                }
                
                { (havePermissionReadZones || havePermissionReadBranches) ?
                    <li>
                        <div className="dropdownBranch">
                            <input type="checkbox" id={idSidebarBranch} />

                            <label className="dropdownBranch__face" htmlFor={idSidebarBranch}>
                                <div className={zonesNav ? "dropdownBranch__text activeSubmenu" : "dropdownBranch__text"}>
                                    <BranchOffices /> <span>Sucursales</span> 
                                    <AngleRight className="icon-angle" />
                                </div>
                            </label>

                            <ul className="dropdownBranch__items">
                                {
                                    havePermissionReadZones ?
                                        <li>
                                        <NavLink to="/zones" activeClassName='activeZones'>
                                            <IoMapOutline /> <span>Regiones</span>
                                        </NavLink>
                                    </li> : null
                                }

                                {
                                    havePermissionReadBranches ?
                                    <li>
                                        <NavLink to="/branch-offices" activeClassName='activeZones'>
                                            <BranchOffices style={{ marginRight: 16 }} /> <span>Sucursales</span>
                                        </NavLink>
                                    </li> : null
                                }
                            </ul>
                        </div>
                    </li> : null
                }

                { havePermissionReadProducts ?
                    <li className="navWrap__menu-item">
                        <NavLink to="/products">
                            <ProductTag />
                            <span>Productos</span>
                        </NavLink>
                    </li> : null
                }

                { (havePermissionReadSales || havePermissionReadChargebacks || havePermissionReadConciliations) ?
                    <li>
                        <div className="dropdownSells">
                            <input type="checkbox" id={idSidebar} />

                            <label className="dropdownSells__face" htmlFor={idSidebar}>
                                <div className={sellsNav ? "dropdownSells__text activeSubmenu" : "dropdownSells__text"}>
                                    <Sells style={{ marginRight: 17 }} /> <span>Ventas</span> 
                                    <AngleRight className="icon-angle" />
                                </div>
                            </label>

                            <ul className={`dropdownSells__items`}>
                                {
                                    havePermissionReadSales &&
                                        <li>
                                            <NavLink to="/sells" activeClassName='activeSells'>
                                                <Sells style={{ marginRight: 17 }} /> <span>Ventas</span>
                                            </NavLink>
                                        </li>
                                }
                            
                                {
                                    havePermissionReadConciliations &&
                                        <li>
                                            <NavLink to="/conciliations" activeClassName='activeSells'>
                                                <Conciliation style={{ marginRight: 16 }} /> <span>Conciliaciones</span>
                                            </NavLink>
                                        </li>
                                }

                                {
                                    havePermissionReadChargebacks && 
                                        <li>
                                            <NavLink to="/chargebacks" activeClassName='activeSells'>
                                                <FaRegCreditCard style={{ marginRight: 17 }} /> <span>Contracargos</span>
                                            </NavLink>
                                        </li>
                                }
                            </ul>
                        </div>
                    </li> : null
                }

                { havePermissionReadClients ?
                    <li className="navWrap__menu-item">
                        <NavLink to="/clients">
                            <Clients />
                            <span>Clientes</span>
                        </NavLink>
                    </li> : null
                }

                { havePermissionReadPaymentLinks ?
                    <li className="navWrap__menu-item">
                        <NavLink to="/payment-links">
                            <LinksIcon />
                            <span>Links de pago</span>
                        </NavLink>
                    </li> : null
                }

                {/*
                    havePermissionReadProducts
                    ? 
                        <li className="navWrap__menu-item">
                            <NavLink to="/invoices">
                                <IoReceiptOutline />
                                <span>Facturas</span>
                            </NavLink>
                        </li>
                    :
                        null
            */}

                { havePermissionReadRefunds ?
                    <li className="navWrap__menu-item">
                        <NavLink to="/refund">
                            <PaymentLinks />
                            <span>Devoluciones</span>
                        </NavLink>
                    </li> : null
                }

                { /* <li className="navWrap__menu-item">
                    <NavLink to="/integrations">
                        <Integrations />
                        <span>Integraciones</span>
                    </NavLink>
                </li> */ }

                <li className="navWrap__menu-item">
                    <a href='https://paycode8360.zendesk.com/hc/es-419'>
                        <BiSupport />
                        <span>Soporte</span>
                    </a>
                </li>

                <li className="navWrap__menu-item">
                    <a href='https://plataforma.paycode.com.mx/'>
                        <IoReturnDownBackSharp />
                        <span>Ir al dashboard viejo</span>
                    </a>
                </li>
            </ul>
        </>
    )
}

const mapStateToProps = ({ sidebarReducer, userReducer }) => ({
    sidebar: sidebarReducer?.sidebar ?? false,
    permissions: userReducer?.permission ?? [],
    permissionsSuper: userReducer?.business?.modules ?? [],
});

export default connect(mapStateToProps)(Sidebar);

import {
    FETCHING_PASSWORD_RESET,
    GET_PASSWORD_RESET,
    ERROR_PASSWORD_RESET
} from "store/types";

const initialState = {
    fetching : undefined,
    success: false,
    successMessage: "",
    error: false,
    errorMessage: "",
}

const passwordResetReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCHING_PASSWORD_RESET:
            return{
                ...state,
                fetching: action?.payload,
            };
        case GET_PASSWORD_RESET:
            return{
                ...state,
                success: action?.payload.success,
                successMessage: action?.payload.msg,
                error: false,
                errorMessage: "",
            };
        case ERROR_PASSWORD_RESET:
            return{
                ...state,
                error: action?.payload?.error,
                errorMessage: "Ups! Hubo un error al actualizar tu contraseña, inténtalo nuevamente",
                fetching: false,
            };
        default:
            return state;
    }
};

export default passwordResetReducer;
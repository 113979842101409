import {
    SET_LAST_ROUTE_VISITED
} from "store/types";

const setLastRouteVisited = data => ({
    type: SET_LAST_ROUTE_VISITED,
    payload: data,
});

const RouteActions = {
    setLastRouteVisited,
};

export default RouteActions;
import {
	GET_BRANCHES,
	FETCHING_ALL_BRANCHES,
	GET_ALL_BRANCHES,
	ERROR_ALL_BRANCHES,
	SET_PAGE_BRANCES,
} from "store/types";

const initialState = {
	branchPage: 1,
	allBranches: {},
	fetching: undefined,
	error: false,
	errorMessage: "",
};

const branchesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_BRANCHES:
			return {
				...state,
				branches: action?.payload
			};
		case FETCHING_ALL_BRANCHES:
			return {
				...state,
				fetching: action?.payload,
			};
		case GET_ALL_BRANCHES:
			return {
				...state,
				allBranches: action?.payload,
				error: false,
				errorMessage: "",
			};
		case ERROR_ALL_BRANCHES:
			return {
				...state,
				error: action?.payload,
				errorMessage: "Ups! Hubo un error al obtener las sucursales",
				fetching: false,
				allBranches: {}
			};
		case SET_PAGE_BRANCES:
			return {
				...state,
				branchPage: action?.payload
			};
		default:
			return state;
	}
};

export default branchesReducer;
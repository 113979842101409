import Sidebar         from "components/layout/Sidebar"
import CustomDrawer    from '../CustomDrawer';
import CustomAvatar    from "components/Ui/CustomAvatar"
import CustomBarBottom from '../CustomBarBottom';
import Marquee from "components/Ui/Marquee";
import "./styles.scss";

const AppLayout = ({ children }) => {
  return (
    <div className="layout">
		<nav className="navWrap open__menu">
			<Sidebar idSidebar="sidebarLayout" idSidebarBranch="sidebarBranchLayout" />
		</nav>
		<main className="layout__main shrink-main">
        	{/* <Marquee msg='Versión Beta' /> */}
			<CustomDrawer />
			{ children }
			<div className="layout__main-avatar">
				<CustomAvatar
					placementAvatar="bottom"
				/>
			</div>
		</main>
		<CustomBarBottom />
    </div>
  )
}

export default AppLayout;
